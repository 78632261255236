<template>
  <travio-center-container pageTitle="Hotlist Details" gridWidth="2/3">
    <vx-card class="mb-5">
      <div class="vx-row">
        <div class="vx-col w-1/2">
          <travelify-display-field label="List Id" 
            :value='hotlistSummary.id' />
          <travelify-display-field label="List Name" 
            :value='hotlistSummary.name' />
          <travelify-display-field label="Customer Name" 
            :value='hotlistSummary.customerFullName' />
          <travelify-display-field label="Customer Email" 
            :html='hotlistSummary.customerEmail' />
        </div>
        <div class="vx-col w-1/2">
          <travelify-display-field label="Updated" 
            :value='updatedDate' />
          <travelify-display-field label="Created" 
            :value='createdDate' />
        </div>
      </div>
      
    </vx-card>
    <vx-card :set="itemdata = JSON.parse(item.itemData)" v-for="(item,index) in this.hotlistItemsList" class="mb-5" :key="index">
      <flight-order-item v-if="item.productTypeId =='1'" :orderData="hotlistSummary" :orderItem="item" :isHotlist="true"/>
      <accomodation-item v-if="item.productTypeId =='2'" :orderItem="item" :itemdata="itemdata" :orderdata="hotlistSummary" :isHotlist="true" />
      <package-holidays-item v-if="item.productTypeId =='3'" :orderData="hotlistSummary" :itemdata="itemdata" :orderItem="item" :isHotlist="true" />
      <tickets-attractions-item v-if="item.productTypeId =='4'" :orderData="hotlistSummary" :itemdata="itemdata" :orderItem="item" :isHotlist="true" /> 
      <taxis-transfer-item v-if="item.productTypeId =='5'" :orderData="hotlistSummary" :itemdata="itemdata" :orderItem="item" :isHotlist="true" /> 
      <airport-extras-item v-if="item.productTypeId =='6'" :orderData="hotlistSummary" :itemdata="itemdata" :orderItem="item" :isHotlist="true" /> 
      <insurance-item v-if="item.productTypeId =='7'" :orderData="hotlistSummary" :orderItem="item" :isHotlist="true" />
      <car-rental-item v-if="item.productTypeId =='8'" :orderData="hotlistSummary" :itemdata="itemdata" :orderItem="item" :isHotlist="true" /> 
    </vx-card>
  
    
  </travio-center-container>
  
</template>

<script>
import FlightOrderItem from '../ordermanager/components/FlightOrderItem.vue'
import AccomodationItem from '../ordermanager/components/AccomodationItem.vue'
import InsuranceItem from '../ordermanager/components/InsuranceItem.vue'
import TicketsAttractionsItem from '../ordermanager/components/TicketsAttractionsItem.vue'
import CarRentalItem from '../ordermanager/components/CarRentalItem.vue'
import PackageHolidaysItem from '../ordermanager/components/PackageHolidaysItem.vue'
import TaxisTransferItem from '../ordermanager/components/TaxisTransferItem.vue'
import AirportExtrasItem from '../ordermanager/components/AirportExtrasItem.vue'
import TravioCenterContainer from '../../components/travio-pro/TravioCenterContainer.vue'

import TravelifyDisplayField from '@/components/travio-pro/TravelifyDisplayField.vue'
import { format } from 'date-fns'

export default {
  props: {
    applicationId: { required: true },
    customerId: { required: true },
    hotlistId: { required: true },
  },
  components: {
    FlightOrderItem,
    AccomodationItem,
    InsuranceItem,
    PackageHolidaysItem,
    TicketsAttractionsItem,
    CarRentalItem,
    TaxisTransferItem,
    AirportExtrasItem,
    TravelifyDisplayField,
    TravioCenterContainer
  },
  data () {
    return {
      hotlistSummary: {},
      hotlistItemsList: []
    }
  },
  computed: {
    activeAppName () {
      return this.$store.state.ActiveApplicationName
    },
    activeUser () {
      return this.$store.state.AppActiveUser
    },
    updatedDate () {
      return this.hotlistSummary.updated && format(new Date(this.hotlistSummary.updated) , this.activeUser.dateTimeFormat)
    },
    createdDate () {
      return this.hotlistSummary.created && format(new Date(this.hotlistSummary.created) , this.activeUser.dateTimeFormat)
    }
  },
  async mounted () {
    try {
      const response = await this.$http.get(`api/customersetting/${this.applicationId}/customer/${this.customerId}/hotlist/${this.hotlistId}`)
      const { hotlistItemsList, ...responseWithoutItems } = response.data
      this.hotlistSummary = Object.assign({},responseWithoutItems)
      this.hotlistItemsList = hotlistItemsList
    } catch (error) {
      this.$_notifyFailure(error)
    }
  },

}
</script>

<style>
  .vx-row.align-items-end {
    align-items: flex-end;
  }
  .flatpickr-calendar.multiMonth{
        width: 640px !important;
  }
  .table-grid-item {
    display: grid;
    grid-template-columns: 20% 30% 20% 30%;
  }
  .table-grid-item .label-field h4,
  .table-grid-item-col-2 .label-field h4,
  .table-grid-item-col-5 .label-field h4,
  .table-grid-item-col-1 .label-field h4,
  .table-grid-item-col-1 .label-field,
  .table-grid-item-col-3 .label-field h4  {
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
  }
  .table-grid-item .text-field span,
  .table-grid-item-col-2 .text-field span,
  .table-grid-item-col-5 .text-field span{
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    word-break: break-word;
  }
  .table-grid-item-col-1{
    display: grid;
    grid-template-columns: auto;
    margin-bottom:10px;
  }
  .table-grid-item-col-1:last-child{
    margin:0;
  }
  .table-grid-item-col-1-pd{
    display: grid;
    grid-template-columns: auto;    
    padding-left: 25px;
  }
  .table-grid-item-col-2{
    display: grid;
    grid-template-columns: 20% 80%;
  }
  .label-field .table-grid-item-col-2 {
    display: grid;
    grid-template-columns: 40% 60%;
  }
  .table-grid-item-col-3{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  .table-grid-item-col-4.order-list{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
  .table-grid-item-col-2.order-list{
    display: grid;
    grid-template-columns: 1fr 2fr;
    border: 1px solid #d7d7dd;
    padding: 10px;
  }
  .custom-table-section{
    border: 1px solid #d7d7dd;
    padding: 10px;
  }
  .table-grid-item-col-5{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 15px;
  }
  .text-field.multi-line {
    display: grid;
    grid-template-columns: auto;
  }
  .img-field img{
    width:100%;
    height:100%;
    border-radius: 5px;
  }  
  .order-details .ag-grid-table{
    height:320px;
  }
  .table-grid-item-col-2-pd {
    padding-left: 20px;
  }
  .img-field img {
    width: 180px;
    height: auto;
    margin: 0 auto;
}
.guests-header-item,.guests-body-item {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    border: 1px solid #d7d7dd;
    padding: 10px;
}
.guest-details {
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
}
.guest-body-detail-item{
    font-size:16px;
    font-weight: 400;
    line-height: normal;
}
.img-field h4 {
    text-align: center;
}
.guest-section {
    padding-top: 10px;
}
.guests-table-layout {
    padding-top: 10px;
}
.rating-star svg{
  width:15px;
  height:15px;
}
h4{
  word-break: break-all;
}
   .ag-cell, .ag-header-cell-text {
    text-overflow: clip !important;
    overflow: visible !important;
    white-space: normal !important;
    line-height: normal !important;
    word-break: break-word;
}
.ag-cell, .ag-header-cell{
  padding:10px 5px !important;
}
.text-field.d-flex {
    display: flex;
    flex-wrap: nowrap;
}
</style>